import { DefaultProps } from '../interface';
import Table from 'react-bootstrap/Table';
import { trackReadMoreDocument } from './tracking';
import './rental-type.scss';
import { FunctionComponent } from 'react';

const json = {
  th: 'บริษัทรถเช่าทั้งสองประเภทแตกต่างกันอย่างไร?',
  en: 'What are the differences between the two types of car rental companies?'
};
const RentalTypeDifferentBox: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const tableContent = {
    th: [
      {
        icon: 'icon-credit-card',
        topic: (
          <>
            <b>ใช้บัตรเครดิต</b>
            <span className="topic-description">เพื่อชำระเงินและรับรถ</span>
          </>
        ),
        inter: <i className="icon-ok" />,
        local: <i className="icon-ok" />,
        localText: 'มีค่าธรรมเนียมเพิ่มเติม'
      },
      {
        icon: 'icon-baht',
        topic: (
          <>
            <b>ใช้เงินสด/โอนเงิน</b>
            <span className="topic-description">เพื่อชำระเงินและรับรถ</span>
          </>
        ),
        inter: '-',
        local: <i className="icon-ok" />,
        localText: ''
      },
      {
        icon: 'icon-file',
        topic: (
          <>
            <b>ไม่ต้องส่งเอกสารก่อนเช่า</b>
            <span className="topic-description">เช่น ตั๋วเครื่องบิน, สลิปเงินเดือน (กรณีเช่าใน กทม.)</span>
            <div>
              <a
                href={`/${prefix}/documents/`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={trackReadMoreDocument}
              >
                อ่านเพิ่มเติมเกี่ยวกับเอกสาร
              </a>
            </div>
          </>
        ),
        inter: <i className="icon-ok" />,
        local: '-',
        localText: ''
      },
      {
        icon: 'icon-thunder',
        topic: (
          <>
            <b>ยืนยันการจองทันที</b>
          </>
        ),
        inter: <i className="icon-ok" />,
        local: '',
        localText: 'ยืนยันภายใน 15 นาที'
      },
      {
        icon: 'icon-car',
        topic: (
          <>
            <b>การันตีได้รถคันที่เลือก</b>
          </>
        ),
        inter: '-',
        local: <i className="icon-ok" />,
        localText: ''
      },
      {
        icon: 'icon-location',
        topic: (
          <>
            <b>บริการรับ-ส่งนอกสถานที่</b>
          </>
        ),
        inter: '-',
        local: <i className="icon-ok" />,
        localText: ''
      },
      {
        icon: 'icon-money',
        topic: (
          <>
            <b>การชำระเงิน ณ วันรับรถ</b>
          </>
        ),
        inter: '-',
        local: <i className="icon-ok" />,
        localText: ''
      }
    ],
    en: [
      {
        icon: 'icon-credit-card',
        topic: (
          <>
            <b>Use a credit card </b>
            <span className="topic-description">(for payment and car pickup.)</span>
          </>
        ),
        inter: <i className="icon-ok" />,
        local: <i className="icon-ok" />,
        localText: 'Additional fee'
      },
      {
        icon: 'icon-baht',
        topic: (
          <>
            <b>Use cash/bank transfer </b>
            <span className="topic-description">(for payment and car pickup.)</span>
          </>
        ),
        inter: '-',
        local: <i className="icon-ok" />,
        localText: ''
      },
      {
        icon: 'icon-file',
        topic: (
          <>
            <b>No documents </b>
            <span className="topic-description">
              eg. flight tickets, salary slips (for rentals in Bangkok) required prior renting
            </span>
            <div>
              <a href={`/${prefix}/documents/`} target="_blank" rel="noopener noreferrer">
                Read more about required documents
              </a>
            </div>
          </>
        ),
        inter: <i className="icon-ok" />,
        local: '-',
        localText: ''
      },
      {
        icon: 'icon-thunder',
        topic: (
          <>
            <b>Instant booking confirmation.</b>
          </>
        ),
        inter: <i className="icon-ok" />,
        local: '',
        localText: 'Booking confirmed within 15 minutes.'
      },
      {
        icon: 'icon-car',
        topic: (
          <>
            <b>Assured availability of your chosen car.</b>
          </>
        ),
        inter: '-',
        local: <i className="icon-ok" />,
        localText: ''
      },
      {
        icon: 'icon-location',
        topic: (
          <>
            <b>Off-site pickup and drop-off services available. </b>
          </>
        ),
        inter: '-',
        local: <i className="icon-ok" />,
        localText: ''
      },
      {
        icon: 'icon-money',
        topic: (
          <>
            <b>Payment upon car pickup.</b>
          </>
        ),
        inter: '-',
        local: <i className="icon-ok" />,
        localText: ''
      }
    ]
  };
  const text = json[prefix];
  return (
    <div className="rental-type-compare">
      <h2>{text}</h2>
      <Table striped className="rental-compare-table">
        <thead>
          <tr>
            <th></th>
            <th>
              <div className="badge badge-inter badge--rental-type py-0">
                <i className="icon-inter" />
                INTER
              </div>
            </th>
            <th>
              <div className="badge badge-local badge--rental-type py-0">
                <i className="icon-local" />
                LOCAL
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableContent[prefix].map((row: any, i: number) => (
            <tr key={i}>
              <td>
                <i className={row.icon} style={row.icon === 'icon-thunder' ? { color: '#ffc850' } : {}} />
                {row.topic}
              </td>
              <td>{row.inter}</td>
              <td>
                {row.local}
                {row.localText && (
                  <div>
                    <small>{row.localText}</small>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
export default RentalTypeDifferentBox;
