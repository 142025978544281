export const INTER_LOGOS = [
  {
    img: '/assets/pages/help/rental-type/brand_logo_Sixt.png',
    styles: undefined
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_National.png',
    styles: undefined
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_BizCar.png',
    styles: undefined
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_hertz.png',
    styles: undefined
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_Thrifty.jpg',
    styles: { maxHeight: 25 }
  },
  {
    img: '/assets/pages/help/rental-type/plus5.png',
    styles: { maxHeight: 14, width: 16 }
  }
];

export const LOCAL_LOGOS = [
  {
    img: '/assets/pages/help/rental-type/brand_logo_WorldRent.jpg',
    styles: { maxHeight: 35 }
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_TN.jpg',
    styles: { maxHeight: 35 }
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_True_leasing.png',
    styles: { maxHeight: 30 }
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_Luv_drive.jpg',
    styles: { maxHeight: 35 }
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_Ranway.jpg',
    styles: { maxHeight: 35 }
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_Khaen_nakhon.jpg',
    styles: { maxHeight: 35 }
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_Lion.png',
    styles: { maxHeight: 35 }
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_AOR.jpg',
    styles: { maxHeight: 35 }
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_CR.jpg',
    styles: { maxHeight: 35 }
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_Hatyai.jpg',
    styles: { maxHeight: 35 }
  },
  {
    img: '/assets/pages/help/rental-type/brand_logo_PATTAYA.jpg',
    styles: { maxHeight: 35 }
  },
  {
    img: '/assets/pages/help/rental-type/plus500.png',
    styles: { maxHeight: 14, width: 30 }
  }
];
