import { DefaultProps } from '../interface';
import { INTER_LOGOS, LOCAL_LOGOS } from './const';
import RentalTypeCompareBox from './compare-box';
import Card from 'react-bootstrap/Card';
import './rental-type.scss';
import { FunctionComponent } from 'react';

const content = {
  th: [
    'Drivehub มีบริษัทรถเช่าให้บริการคุณถึง 2 แบบ เพื่ออำนวยความสะดวกให้ทั้งลูกค้าที่มีและไม่มีบัตรเครดิต ทั้งนี้บริษัทรถเช่าแต่ละประเภทจะมีข้อแตกต่างกันดังนี้',
    'บริษัทรถเช่ารายใหญ่',
    'มาตรฐานสากล ชำระค่าเช่าบนหน้าเว็บ พร้อมยืนยันการจองทันที ไม่ต้องรอการติดต่อและยืนยันกับบริษัท',
    'มองหาสัญลักษณ์',
    'ต้องใช้บัตรเครดิตเพื่อยืนยันการรับรถเท่านั้น!',
    'หากไม่นำไปบริษัทรถเช่าจะไม่สามารถปล่อยรถให้คุณได้',
    'บริษัทรถเช่าท้องถิ่น',
    <>
      บริการเป็นกันเอง พร้อมรถเช่าหลากหลายรุ่นให้เลือก
      <b>สามารถใช้เงินสดในการเช่าได้</b>
    </>
  ],
  en: [
    'Drivehub provides car rental services in two types to cater to both customers with and without credit cards, ensuring convenience for everyone. However, each type of car rental company may have the following differences:',
    'Interbrand Car Rental Companies',
    'International standards, make the rental payment on the website, and receive immediate booking confirmation. No waiting for communication or confirmation from the company.',
    'Look for the symbol:',
    'Credit card is required only to confirm vehicle pick-up!',
    `If you don't provide it, the car rental company won't be able to release the car to you.`,
    'Local Car Rental Companies',
    <>
      Providing a welcoming service along with a diverse range of car models to select from,
      <b> you have the option to pay for the rental using cash.</b>
    </>
  ]
};

const RentalType: FunctionComponent<DefaultProps> = ({ prefix }) => {
  const text = content[prefix];
  return (
    <div className="rental-type">
      <p className="mb-2">{text[0]}</p>
      <div className="rental-type-box">
        <div className="source-box">
          <div className="d-flex justify-content-between mb-2">
            <h2>{text[1]}</h2>
            <div className="d-flex flex-column">
              <small>{text[3]}</small>
              <div className="badge badge-inter badge--rental-type py-0">
                <i className="icon-inter" />
                INTER
              </div>
            </div>
          </div>
          <p className="mb-2">{text[2]}</p>
          <div className="example-dealer mb-3">
            {INTER_LOGOS.map((d, i) => (
              <Card key={i} className="card--dealer shadow">
                <Card.Img src={d.img} style={d.styles ? { ...d.styles } : {}} />
              </Card>
            ))}
          </div>
          <div className="box--attention rental-type-attention">
            <i className="icon-attention" />
            <div>
              <h3>{text[4]}</h3>
              <p className="mb-0">{text[5]}</p>
            </div>
          </div>
        </div>
        <div className="source-box">
          <div className="d-flex justify-content-between mb-2">
            <h2>{text[6]}</h2>
            <div className="d-flex flex-column">
              <small>{text[3]}</small>
              <div className="badge badge-local badge--rental-type py-0">
                <i className="icon-local" />
                LOCAL
              </div>
            </div>
          </div>
          <p className="mb-2">{text[7]}</p>
          <div className="example-dealer">
            {LOCAL_LOGOS.map((d, i) => (
              <Card key={i} className="card--dealer shadow">
                <Card.Img src={d.img} style={d.styles ? { ...d.styles } : {}} />
              </Card>
            ))}
          </div>
        </div>
      </div>
      <RentalTypeCompareBox prefix={prefix} />
    </div>
  );
};

export default RentalType;
